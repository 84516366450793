@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville&display=swap');

.App {
  text-align: center;
}

.grid-container, .work-grid-container {
    display: inline-grid;
    grid-template-columns: auto auto auto;
    padding: 5px;
}

/*.work-grid-container {*/
/*    display: inline-grid;*/
/*    grid-template-columns: auto auto;*/
/*    padding: 5px;*/
/*}*/

.grid-item, .work-grid-item {
    padding: 5px;
    text-align: center;
}

.grid-item img {
    height: auto;
    width: 100%;
}

.work-grid-item img {
    float: left;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

html, body, #root, .App {
    min-height: 100vh;
    margin: 0;
    display: flex;
    flex-flow: column;
}

.why-photography h1, .about-my-work h1 {
    font-family: 'Libre Baskerville', serif;
    font-weight: 400;
    font-size: 4vw;
}

.why-photography p, .about-my-work p {
    font-size: 2vw;
}

body {
    position: relative;
    text-align: center;
    margin: 4em;
}

.App-header {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.fas {
    font-size: 20px;
}

#content {
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 400;
    font-style: normal;    
    font-size: 20px;
    color: white;
    text-align: center;
    padding-bottom: 50px;
}

ul {
    text-align: center;
    list-style-position: inside;
}

.header {
    text-align: left;
    margin-left: 5vw;
    padding-top: 15vh;
    width: 90%;
    position: relative;
}

.name {
    font-family: 'Libre Baskerville', serif;
    font-weight: 400;
    font-size: 5vw;
}

.who, .what, .where, .when, .why, .how {
    font-family: 'Libre Baskerville', serif;
    font-weight: 400;
    font-size: 5vh;
}

.roles {
    font-size: 3vw;
}

::selection {
    background: #FFFFFF;
}

.fab {
    color: #fff;
    margin: 10px;
    font-size: 1.5em;
}

.fas {
    color: #fff;
    margin: 10px;
    font-size: 1.5em;
}

.welcome {
    font-size: 1.5vw;
}

.fas:hover {
    color: #000000;
}

.fab:hover {
    color: #000000;
}

.subHeader {
    font-family: open-sans,sans-serif;
    font-weight: 700;
    font-style: normal;
}

.bg {
    height: 100%;
    background-color: #61876E;
}

#social-media-buttons {
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 400;
    font-style: normal;
    text-align: center;
    background-color: #3C6255;
    color: #fff;
    padding: 18px;
    width: 100%;
}

.fa-2x {
    color: #fff;
}

.navbar.navbar-inverse {
    background: none;
    padding: 10px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 400;
    font-style: normal;     
    font-size: 15px;
    border-radius: 0 !important;
    border-color: transparent;
}

.navbar.navbar-inverse li {
    text-transform: lowercase;
}

.navbar.navbar-inverse a {
    color: #fff;
}

.nav.navbar-nav.navbar-right li a {
    color: #fff;
}

#toastmasters {
    color: #000000;
}

.navbar-inverse .navbar-brand:hover,
.navbar-inverse .navbar-brand:focus {
    color: #000 !important;
    text-decoration: underline;
}

ul.nav a:hover {
    color: #000000 !important;
    text-decoration: underline !important;
}

li a,
.dropdown-toggle {
    display: inline-block;
    color: white;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
}

li a:hover,
.dropdown:hover .dropbtn {
    background-color: transparent;
    cursor: pointer;
}

li.dropdown {
    display: inline-block;
}

.me img {
    height: auto;
    width: 100%;
    max-width: 100%;
    display: flex;
}

.navbar-brand {
    font-family: 'Libre Baskerville', serif;
    font-weight: 700;
    font-style: normal;    
    font-size: 20px !important;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #336E7B;
    min-width: 160px;
    box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.2);
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
}

.dropdown:hover .dropdown-content {
    display: block;
}
